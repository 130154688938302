import { FaInfoCircle } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";

const Business = props => {
  const { theme } = props;

  return (
    <React.Fragment>
      {/* --- STYLES --- */}
      <style jsx>{`
        .business {
          margin: ${theme.space.l} 0;
          padding: ${theme.space.s};
          font-size: ${theme.font.size.xs};
          line-height: ${theme.font.lineHeight.xl};
          background: ${theme.color.neutral.gray.a};
          border-radius: ${theme.size.radius.small};
          color: ${theme.color.neutral.gray.h};

          :global(a) {
            color: ${theme.color.brand.primary};
            font-weight: ${theme.font.weight.bold};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Business.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Business;
